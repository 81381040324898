import { styled } from '../../../theme/stitches.config';

import { CARD_IMAGE_DIMENSIONS, DETAILS_IMAGE_DIMENSIONS } from './dimensions';

export const StyledContainer = styled('div', {
  backgroundColor: 'var(--BackgroundBase1)',
  borderColor: '$Neutral02',
  borderStyle: 'solid',
  borderWidth: 0.25,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  overflow: 'hidden',
  variants: {
    location: {
      card: {
        borderRadius: 8,
        maxWidth: '100%',
        '@sm': {
          height: CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
        },
        '@md': {
          height: CARD_IMAGE_DIMENSIONS.MD.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.MD.CONTAINER_WIDTH,
        },
        '@lg': {
          height: CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
        },
      },
      details: {
        borderRadius: 16,
        '@sm': {
          borderRadius: 0,
          height: DETAILS_IMAGE_DIMENSIONS.SM.CONTAINER_HEIGHT,
        },
        '@md': {
          height: DETAILS_IMAGE_DIMENSIONS.MD.CONTAINER_HEIGHT,
          width: DETAILS_IMAGE_DIMENSIONS.MD.CONTAINER_WIDTH,
        },
        '@lg': {
          height: DETAILS_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: DETAILS_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
        },
      },
    },
  },
});

export const StyledLeftContainer = styled('div', {
  backgroundColor: '$Neutral02',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  compoundVariants: [
    {
      fullWidth: true,
      location: 'card',
      css: {
        width: '100%',
        '@md': {
          width: DETAILS_IMAGE_DIMENSIONS.MD.CONTAINER_WIDTH,
        },
        '@lg': {
          width: DETAILS_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
        },
      },
    },
    {
      fullWidth: true,
      location: 'details',
      css: {
        '@sm': {
          height: DETAILS_IMAGE_DIMENSIONS.SM.CONTAINER_HEIGHT,
        },
        '@md': {
          width: DETAILS_IMAGE_DIMENSIONS.MD.CONTAINER_WIDTH,
        },
        '@lg': {
          width: DETAILS_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
        },
      },
    },
  ],
  variants: {
    fullWidth: {
      true: {
        width: CARD_IMAGE_DIMENSIONS.CONTAINER_WIDTH,
      },
    },
    location: {
      card: {
        maxWidth: '100%',
        '@sm': {
          height: CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.LEFT_WIDTH,
        },
        '@md': {
          height: CARD_IMAGE_DIMENSIONS.MD.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.MD.LEFT_WIDTH,
        },
        '@lg': {
          height: CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.LEFT_WIDTH,
        },
      },
      details: {
        maxWidth: '100%',
        '@sm': {
          height: DETAILS_IMAGE_DIMENSIONS.SM.CONTAINER_HEIGHT,
          width: '100%',
        },
        '@md': {
          height: DETAILS_IMAGE_DIMENSIONS.MD.CONTAINER_HEIGHT,
          width: DETAILS_IMAGE_DIMENSIONS.MD.LEFT_WIDTH,
        },
        '@lg': {
          height: DETAILS_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: DETAILS_IMAGE_DIMENSIONS.LEFT_WIDTH,
        },
      },
    },
  },
});

export const StyledRightContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  variants: {
    location: {
      card: {
        maxWidth: '100%',
        '@sm': {
          height: CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.RIGHT_WIDTH,
        },
        '@md': {
          height: CARD_IMAGE_DIMENSIONS.MD.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.MD.RIGHT_WIDTH,
        },
        '@lg': {
          height: CARD_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: CARD_IMAGE_DIMENSIONS.RIGHT_WIDTH,
        },
      },
      details: {
        maxWidth: '100%',
        '@sm': {
          display: 'none',
        },
        '@md': {
          height: DETAILS_IMAGE_DIMENSIONS.MD.CONTAINER_HEIGHT,
          width: DETAILS_IMAGE_DIMENSIONS.MD.RIGHT_WIDTH,
        },
        '@lg': {
          height: DETAILS_IMAGE_DIMENSIONS.CONTAINER_HEIGHT,
          width: DETAILS_IMAGE_DIMENSIONS.RIGHT_WIDTH,
        },
      },
    },
  },
});

export const StyledRightBottomContainer = styled('div', {
  backgroundColor: '$Neutral02',
  backgroundPosition: 'center',
  backgroundSize: 'auto',
  variants: {
    location: {
      card: {
        maxWidth: '100%',
        '@sm': {
          height: CARD_IMAGE_DIMENSIONS.RIGHT_HEIGHT,
        },
        '@md': {
          height: CARD_IMAGE_DIMENSIONS.MD.RIGHT_HEIGHT,
        },
        '@lg': {
          height: CARD_IMAGE_DIMENSIONS.RIGHT_HEIGHT,
        },
      },
      details: {
        maxWidth: '100%',
        '@md': {
          height: DETAILS_IMAGE_DIMENSIONS.MD.RIGHT_HEIGHT,
        },
        '@lg': {
          height: DETAILS_IMAGE_DIMENSIONS.RIGHT_HEIGHT,
        },
      },
    },
  },
});

export const StyledRightTopContainer = styled('div', {
  backgroundColor: '$Neutral02',
  backgroundPosition: 'center',
  backgroundSize: 'auto',
  variants: {
    location: {
      card: {
        height: CARD_IMAGE_DIMENSIONS.RIGHT_HEIGHT,
        maxWidth: '100%',
        '@md': {
          height: CARD_IMAGE_DIMENSIONS.MD.RIGHT_HEIGHT,
        },
        '@lg': {
          height: CARD_IMAGE_DIMENSIONS.RIGHT_HEIGHT,
        },
      },
      details: {
        height: DETAILS_IMAGE_DIMENSIONS.RIGHT_HEIGHT,
        maxWidth: '100%',
        '@md': {
          height: DETAILS_IMAGE_DIMENSIONS.MD.RIGHT_HEIGHT,
        },
        '@lg': {
          height: DETAILS_IMAGE_DIMENSIONS.RIGHT_HEIGHT,
        },
      },
    },
  },
});

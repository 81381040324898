import React from 'react';

import {
  StyledContainer,
  StyledLeftContainer,
  StyledRightBottomContainer,
  StyledRightContainer,
  StyledRightTopContainer,
} from './styles';
import type { VendorImagesProps } from './types';

export default function VendorImages({
  image,
  location = 'card',
  productImages = [],
}: VendorImagesProps) {
  return (
    <StyledContainer location={location}>
      <StyledLeftContainer
        fullWidth={productImages.length < 2}
        location={location}
        css={{
          backgroundImage: `url(${image})`,
        }}
      />

      {(productImages.length || 0) > 1 && (
        <StyledRightContainer location={location}>
          <StyledRightTopContainer
            location={location}
            css={{
              backgroundImage: `url(${productImages[0]})`,
            }}
          />
          <StyledRightBottomContainer
            location={location}
            css={{
              backgroundImage: `url(${productImages[1]})`,
            }}
          />
        </StyledRightContainer>
      )}
    </StyledContainer>
  );
}
